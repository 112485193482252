//1.引入vue
import Vue from "vue"
//2.引入vue-router
import VueRouter from "vue-router";
//3.注册vue-router
Vue.use(VueRouter);
//4.路径
const routes = [
  {
    path: "/contract",
    name: "contract",
    component: () => import("@/views/contract.vue"),
  },
  {
    path: "/offer",
    name: "offer",
    component: () => import("@/views/offer.vue"),
  },
  {
    path: "/outordersummary",
    name: "outordersummary",
    component: () => import("@/views/outordersummary.vue"),
  },
  {
    path: "/outorder",
    name: "outorder",
    component: () => import("@/views/outorder.vue"),
  },
  
];
//4.创建
const router = new VueRouter({
  mode: 'hash',//模式
  routes,
});
//5.共享
export default router;
